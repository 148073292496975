<template>
	
	<div class="grid">
		<Toast />
		<template v-if="ingresoCostumer==false">
			<div class="col-12 lg:col-12">
			<center><h2>Solicitudes Trasporte Especial</h2></center>
			</div>
			<template v-for="(customer,index) in customers" :key="index">
				<div class="col-12 lg:col-6">
					<div class="card height-90 flex flex-column m-3">
						<div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
							<img src="layout/images/widgets/feature-onboarding.svg" alt="feature-faq">
						</div>
						<div class="flex flex-column align-items-center mt-3">
							<h5 class="mb-2">{{customer.customer.name}}</h5>
						</div>
						<div class="flex justify-content-between align-items-center pt-3 mt-auto">
							<small class="muted-text"></small>
							<template v-if="customer.status==1">
								<Button label="Ingresar" @click="abrirCustomer(customer,index)"></Button>
							</template>
						</div>
					</div>
				</div>
			</template>
		</template>
		<template v-else>
			<div class="col-12 lg:col-12">
				<!-- <center><h2 class="alignment-center">{{ infoCostumer.customer.name }}</h2></center> -->
			</div>
			<div class="col-12 lg:col-12">
			<Toolbar class="mb-4">
				<template v-slot:start>
					<div class="my-2">
						<!-- <Button label="Nuevo" icon="pi pi-plus" class="p-button-raised p-button-success mr-2" @click="openNew" /> -->
					</div>
					<div class="mt-3 ml-5">
						<center><h3>Historicos Solicitudes de Trasporte Especial</h3></center>
					</div>
				</template>
				<template v-slot:end>
					<Button label="Exportar" icon="pi pi-file-excel" class="p-button-raised p-button-success mr-2" @click="closeHistoricos" />
				</template>
			</Toolbar>
			<template v-if="loader==true">
				<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
			</template>
			<DataTable ref="dt" :value="solicitudes_historicos" v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords} customers" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <Dropdown v-model="campo_selected" :showClear="true" :options="campos_tabla" optionLabel="name" optionValue="code" placeholder="Seleccione un Campo" class="mr-2" @click="mostrar"/>
								<template v-if="campo_selected=='estado'">
									<Dropdown v-model="select_estado" :showClear="true" :options="estados_select_historicos" optionLabel="name" placeholder="Seleccione un Campo" />
								</template>
								<template v-if="campo_selected=='num_solicitud'">
									<InputText placeholder="Núm Solicitud" v-model="text_filter" required="true" autofocus />
								</template>
								<template v-if="campo_selected=='funcionario'">
									<InputText placeholder="Ingrese Cédula" v-model="text_filter" required="true" autofocus />
								</template>
								<template v-if="campo_selected=='fecha_hora_inicio'">
											<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="mb-3 mr-1 ml-5"></Checkbox>
							<label for="checkrangofechasolicitud">Rango</label>
									<template v-if="frmfiltros.rangofechasolicitud">
                                        <Calendar v-model="text_filter" :showTime="false" :showIcon="true" :locale="es" class="ml-2" placeholder="Seleccione fecha inicial" />

										<Calendar v-model="frmfiltros.fechasolicitudrango" :minDate="text_filter" :showTime="false" :showIcon="true" :locale="es" class="ml-3" placeholder="Seleccione fecha final"/>
                                    </template>
                                    <template v-else>
										<Calendar v-model="text_filter" :showTime="false" :showIcon="true" :locale="es" class="ml-2" placeholder="Seleccione fecha"/>
                                    </template>
								</template>
								<Button type="button" class="ml-5 mt-2 p-button-raised" label="Buscar" icon="pi pi-search" :loading="loading[0]" @click="load(0)" />
								<Button type="button" class="ml-2 mt-2 p-button-raised" label="Limpiar" icon="pi pi-filter-slash" :loading="loading[0]" @click="limpiar" />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="num_solicitud" header="Núm Solicitud" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Núm Solicitud</span>
							{{slotProps.data.num_solicitud}}
						</template>
					</Column>
					<Column field="fecha_hora_inicio" header="Fecha Hora Inicio" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Fecha Hora Inicio</span>
							{{slotProps.data.fecha_hora_inicio}}
						</template>
					</Column>
					<!-- <Column field="tipo_servicio" header="Tipo Servicio" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Tipo Servicio</span>
							{{slotProps.data.tipo_servicio.descripcion}}
						</template>
					</Column>
					<Column field="tipo_vehiculo" header="Tipo Vehiculo" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Tipo Vehiculo</span>
							{{slotProps.data.tipo_vehiculo.descripcion}}
						</template>
					</Column>
					<Column field="origen" header="Origen" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Origen</span>
							{{slotProps.data.origen.nombre}}
						</template>
					</Column>
					<Column field="destino" header="Destino" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Destino</span>
							{{slotProps.data.destino.nombre}}
						</template>
					</Column>
					<Column field="funcionario" header="Funcionario" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Funcionario</span>
							{{slotProps.data.funcionario.nombre+' '+slotProps.data.funcionario.apellido}}
						</template>
					</Column>
					<Column field="centro_costos" header="Centro de Costos" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Centro de Costos</span>
							{{slotProps.data.centro_costos.descripcion}}
						</template>
					</Column> -->
					<Column field="id_tipo_servicio" header="Tipo Servicio" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Tipo Servicio</span>
							{{slotProps.data.tipo_servicio}}
						</template>
					</Column>
					<Column field="id_clase_vehiculo" header="Tipo Vehiculo" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Tipo Vehiculo</span>
							{{slotProps.data.tipo_vehiculo}}
						</template>
					</Column>
					<Column field="id_origen" header="Origen" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Origen</span>
							{{slotProps.data.origen}}
						</template>
					</Column>
					<Column field="id_destino" header="Destino" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Destino</span>
							{{slotProps.data.destino}}
						</template>
					</Column>
					<Column field="id_funcionario" header="Funcionario" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Funcionario</span>
							{{slotProps.data.nombre+' '+slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="id_centro_costo" header="Centro de Costos" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Centro de Costos</span>
							{{slotProps.data.centro_costos}}
						</template>
					</Column>
					<Column field="placa" header="Placa" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Placa</span>
							{{slotProps.data.placa}}
						</template>
					</Column>
					<Column field="estado" header="Estado" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Estado</span>
							<template v-if="slotProps.data.estado==1">
								<!-- {{'Asignado'}} -->
								<Badge value="Asignado" size="large" severity="primary" class="mr-2"></Badge>
							</template>
							<template v-if="slotProps.data.estado==2">
								<!-- {{'Anulado'}} -->
								<Badge value="Anulado" size="large" severity="danger" class="mr-2"></Badge>
							</template>
							<template v-if="slotProps.data.estado==0">
								<Badge value="Pendiente" size="large" severity="warning" class="mr-2"></Badge>
							</template>
							<template v-if="slotProps.data.estado==3">
								<Badge value="En transito" size="large" severity="success" class="mr-2"></Badge>
							</template>
							<template v-if="slotProps.data.estado==4">
								<Badge value="Finalizado" size="large" :style="{'background-color': '#9c27b0', 'color': '#ffffff'}" class="mr-2"></Badge>
							</template>
						</template>
					</Column>
					<Column field="acciones" header="Acciones">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-raised p-button-rounded p-button-text" @click="verSolicitud(slotProps.data)" />	
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: '550px'}" header="Información de la solicitud" :modal="true" class="p-fluid">
					<div class="field formgrid grid">
						<div class="field col-12">
							<div class="surface-section">
								<!-- <div class="font-medium text-3xl text-900 mb-3">Información de la solicitud</div> -->
								<div class="text-500 mb-5">Encuentre aquí toda la información de la solicitud especificamente por funcionario.</div>
								<ul class="list-none p-0 m-0">
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Solicitud nro°</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{solicitudes.num_solicitud}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Empresa</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{nombregenerador}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Fecha creación de la solicitud</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{fechaactual}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Funcionario que creo la solicitud</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{nombreuser}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Tipo de Servicio</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{solicitudes.tipo_servicio}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Tipo de Vehiculo</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{solicitudes.tipo_vehiculo}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Centro de Costos</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{solicitudes.centro_costos}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Funcionario</div>
										<!-- <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{solicitudes.funcionario.nombre+' '+solicitudes.funcionario.apellido}}</div> -->
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Origen</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{solicitudes.origen.nombre}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Destino</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{solicitudes.destino.nombre}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Fecha y hora de inicio</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{solicitudes.fecha_hora_inicio}}</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Envio de MSM</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
											{{sionomsm(solicitudes.eventos_sms)}}
											<template v-if="solicitudes.eventos_sms==1">
												<label for="Núm Celular"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Núm Celular:&nbsp;&nbsp; {{num_cel}}</label>
											</template>
										</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Envio de Correo</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
											{{sionocorreo(solicitudes.eventos_correo)}}
											<template v-if="solicitudes.eventos_correo==1">
												<label for="eventos_correo"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Correo:&nbsp;&nbsp; {{correo}}</label>
											</template>
										</div>
									</li>
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-4 font-medium">Nota</div>
										<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{solicitudes.notas}}</div>
									</li>
									<template v-if="solicitudes.anulacion">
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-4 font-medium">Motivo de la anulación</div>
											<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{solicitudes.anulacion}}</div>
										</li>
									</template>
								</ul>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="modalFiltros" :style="{width: '450px'}" header="Filtros para Exportar Excel" :modal="true" class="p-fluid">
					<div class="field formgrid grid">
						<div class="field col-12">
							<label for="fecha_inicio">Fecha de inicio</label><Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmFilterExports.rangofechasolicitud" class="mb-2 mr-1 ml-5"></Checkbox>Rango
						</div>
						
						<template v-if="frmFilterExports.rangofechasolicitud">
							<div class="field col-6">
								<Calendar v-model="frmFilterExports.fecha_ini" :showTime="false" :showIcon="true" :locale="es" placeholder="Seleccione fecha inicial" />
							</div>
							<div class="field col-6">
								<Calendar v-model="frmFilterExports.fecha_fin" :minDate="frmFilterExports.fecha_ini" :showTime="false" :showIcon="true" :locale="es" class="ml-3" placeholder="Seleccione fecha final"/>
							</div>
						</template>
						<template v-else>
							<div class="field col-12">
								<Calendar v-model="frmFilterExports.fecha_ini" :showTime="false" :showIcon="true" :locale="es" placeholder="Seleccione fecha inicial" />
							</div>
						</template>
						<div class="field col-12 mt-2">
							<label for="fecha_inicio">Fecha de creación</label><Checkbox id="checkrangofechasolicitud_creacion" :binary="true" v-model="frmFilterExports.rangofechasolicitud_creacion" class="mb-2 mr-1 ml-5"></Checkbox>Rango
						</div>
						
						<template v-if="frmFilterExports.rangofechasolicitud_creacion">
							<div class="field col-6">
								<Calendar v-model="frmFilterExports.fecha_ini_creacion" :showTime="false" :showIcon="true" :locale="es" placeholder="Seleccione fecha creación" />
							</div>
							<div class="field col-6">
								<Calendar v-model="frmFilterExports.fecha_fin_creacion" :minDate="frmFilterExports.fecha_ini_creacion" :showTime="false" :showIcon="true" :locale="es" class="ml-3" placeholder="Seleccione fecha creación final"/>
							</div>
						</template>
						<template v-else>
							<div class="field col-12">
								<Calendar v-model="frmFilterExports.fecha_ini_creacion" :showTime="false" :showIcon="true" :locale="es" placeholder="Seleccione fecha creación" />
							</div>
						</template>
						<div class="field col-12 mt-2">
							<label for="Núm Solicitud">Número de Solicitud</label>
							<InputText placeholder="Núm Solicitud" type="number" v-model="frmFilterExports.num_solicitud" required="true" autofocus />
						</div>
						<div class="field col-12 mt-2">
							<label for="estado">Estado</label>
							<Dropdown v-model="frmFilterExports.estado" :showClear="true" :options="estados_select_historicos" optionValue="code" optionLabel="name" placeholder="Seleccione un Campo" />
						</div>
						<div class="field col-12 mt-2">
							<label for="funcionario">Cédula del Funcionario</label>
							<InputText placeholder="Ingrese Cédula" type="number" v-model="frmFilterExports.cedula_funcionario" required="true" autofocus />
						</div>
						
					</div>
					<template v-if="loader==true">
						<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
					</template>
					<template #footer>
						<Button label="Exportar" id="buttonexports" icon="pi pi-filter-fill" class="p-button-text" @click="export_historicos"/>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modalFiltros = false"/>
					</template>
				</Dialog>
			</div>
		</template>
	</div>
</template>

<script>
import axios from 'axios'
import {FilterMatchMode} from 'primevue/api';
import moment from 'moment';
// import Multiselect from '@vueform/multiselect'
// import ProductService from '../service/ProductService';
export default {
	data() {
		return {
			message: [],
			customers: [],
			username:null,
			email:null,
			count: 0,
			display: false,
			loader: false,
			frmCostos: {
				id_costos: '',
				codigo: '',
				descripcion: '',
				estado: '',
				id_customer: '',
				id_generador: ''
			},
			frmSolicitud: {
				num_solicitud: '',
				fecha_hora: '',
				nombre_user: '',
				tiposervicio: '',
				tipovehiculo: '',
				centrocostos: '',
				funcionario: '',
				fecha_hora_inicio: '',
				id_customer: '',
				placa: '',
				notas: '',
				id_generador: '',
				origen: '',
				destino: '',
				msm: false,
				notify_correo: false
			},
			num_solicitud: '',
			selectgenerador: null,
			generadores: [],
			tipo_servicios: [],
			clase_vehiculo: [],
			selectiposervicio: [],
			nombreuser: '',
			text_filter: '',
			fechaactual: '',
			num_cel: '',
			indexdetalle: '',
			nombregenerador: '',
			campos_tabla: [
				{name: 'Fecha Inicio', code: 'fecha_hora_inicio'},
				{name: 'Número Solicitud', code: 'num_solicitud'},
				{name: 'Estado', code: 'estado'},
				{name: 'Funcionario', code: 'funcionario'}
			],
			estados_select: [
				{name: 'Pendiente', code: '0'},
				{name: 'Asignado', code: '1'},
				{name: 'En transito', code: '3'},
				// {name: 'Anulado', code: '2'},
				// {name: 'Finalizado', code: '4'}
			],
			campos_tabla_historicos: [
				{name: 'Fecha Inicio', code: 'fecha_hora_inicio'},
				{name: 'Número Solicitud', code: 'num_solicitud'},
				{name: 'Estado', code: 'estado'},
				{name: 'Funcionario', code: 'funcionario'}
			],
			estados_select_historicos: [
				// {name: 'Pendiente', code: '0'},
				// {name: 'Asignado', code: '1'},
				// {name: 'En transito', code: '3'},
				{name: 'Anulado', code: '2'},
				{name: 'Finalizado', code: '4'}
			],
			campo_selected: null,
			campo_selected_historicos: null,
			frmfiltros: {
                fechasolicitud: '',
                rangofechasolicitud: false,
                fechasolicitudrango: '',
                cliente: '',
                direccion: '',
                mensajero: '',
                estadopedido: 0
            },
			select_estado: null,
			modalHistoricos: false,
			modalFiltros: false,
			abrireditar: false,
			ingresoCostumer: false,
			infoCostumer: [],
			centro_costos: [],
			origenes: [],
			destinos: [],
			solicitudes: [],
			solicitudes_historicos: [],
			funcionarios: [],
			detalle_solicitud: [],
			products: null,
			loading: [false, false, false],
			productDialog: false,
			deleteProductDialog: false,
			deleteProductAnular: false,
			modalselectempresa: false,
			deleteProductsDialog: false,
			editando: false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			frmFilterExports: {
				fecha_ini: '',
				fecha_fin: '',
				fecha_ini_creacion: '',
				fecha_fin_creacion: '',
				rangofechasolicitud_creacion: false,
				num_solicitud: '',
				estado: '',
				cedula_funcionario: '',
				rangofechasolicitud: false,
				historicos: 1
			}
		}
	},
	components:{
        // Multiselect
		// moment,
    },
	mounted(){
		// this.cargar_empresas();
		this.abrirCustomer();
	},
	methods:{
		abrirCustomer() {
			// var correo =  document.cookie.split(";");
			// var id_customer = correo[4].replace(' IdCustomer=', '');
			const cookieName = 'IdCustomer';
			const cookieUserRol = 'IdUsuarioRol';
			const cookieUserName = 'Nombre';
			
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			});
			var id_customer = cookie[cookieName];
			var id_user_rol = cookie[cookieUserRol];
			this.nombreuser = cookie[cookieUserName];
			moment.locale('es');
			this.fechaactual = moment(new Date()).format('LL');
			this.loadDataTableHistoricos(id_customer);
			this.loadData(id_user_rol);
			this.ingresoCostumer = true;
		},
		toggle(event) {
			this.$refs.op.toggle(event);
		},
		verSolicitud(data){
			this.solicitudes = [];
			this.productDialog = true;
			this.solicitudes = data;
			this.nombregenerador = data.generador.razon_social;
			// this.solicitudes.fecha_hora_inicio = moment(data.fecha_hora_inicio).format('LLLL')
			this.solicitudes.fecha_hora_inicio = data.fecha_hora_inicio;
			this.fechaactual = moment(data.fecha_hora).format('LL');
			this.frmSolicitud.fecha_hora_inicio = data.fecha_hora_inicio;
			this.frmSolicitud.fecha_hora = data.fecha_hora;
			this.frmSolicitud.tiposervicio = data.tiposervicio;
			this.frmSolicitud.tipovehiculo = data.tipovehiculo;
			this.frmSolicitud.centrocostos = data.centrocostos;
			this.frmSolicitud.funcionario = data.funcionario;
			this.frmSolicitud.origen = data.origen;
			this.frmSolicitud.destino = data.destino;
			this.frmSolicitud.msm = data.msm;
			this.frmSolicitud.placa = data.placa;
			this.frmSolicitud.notas = data.notas;
			this.frmSolicitud.notify_correo = data.notify_correo;
		},
		
		limpiar(){
			this.select_estado = null;
			this.campo_selected = null;
			this.frmfiltros.rangofechasolicitud = '';
			this.frmfiltros.fechasolicitudrango = '';
			this.text_filter = '';
			const cookieName = 'IdCustomer';
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			});
			var id_customer = cookie[cookieName];
			this.loadDataTableHistoricos(id_customer);
		},
		mostrar(){
			console.log(this.campo_selected);
		},
		load(index) {
			var _that = this;
			if(this.campo_selected==null){
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor seleccionar un campo e ingresar texto a filtrar', life: 4000});
			}else{
				if(this.campo_selected=='fecha_hora_inicio' && this.text_filter==''){
					this.$toast.add({severity:'error', summary: 'Falta fecha', detail:'Favor seleccionar una fecha a filtrar', life: 4000});

				}else{
					if(this.frmfiltros.rangofechasolicitud==true && this.frmfiltros.fechasolicitudrango==''){
						this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor seleccionar una fecha inicio y una fecha fin a filtrar', life: 4000});
					}else{
						this.loading[index] = true;
						const cookieName = 'IdCustomer';
						

						let cookie = {};
						document.cookie.split(';').forEach(function(el) {
						let [key,value] = el.split('=');
						cookie[key.trim()] = value;
						});
						let id_customer = cookie[cookieName];
						this.loader = true;
						let text = '';
						if(this.campo_selected=='estado'){
							text = this.select_estado.code;
						}else{
							text = this.text_filter;
						}
						axios.post('/filtrar_solicitudes_historicos', {nom_campo: this.campo_selected,text: text,id_customer: id_customer,fecha_fin: this.frmfiltros.fechasolicitudrango,rango: this.frmfiltros.rangofechasolicitud}).then(response => {
							// _that.generadores = response.data.data;
							_that.loader = false;
							_that.solicitudes_historicos = response.data.data;
							_that.loading[index] = false;
							_that.text_filter = '';
							// _that.loadDataTable(_that.frmSolicitud.id_customer);
						}).catch(function (error) {
							_that.loader = false;
							if (error.response) {
								_that.text_filter = '';
                            if(error.response.status==422){
								_that.$toast.add({severity:'error', summary: 'No existe funcionario', detail:error.response.data.message, life: 4000});
                                // var nit_invalido = document.getElementById("nit_invalid");
                                // nit_invalido.classList.add('p-invalid'); 
								_that.loader = false;
                            }
							_that.loading[index] = false;
							}
						});
					}
				}
				
			}
		},
		seleccionar(event) {
			console.log(event);
			
			const calendar = this.$refs.calendario ?? null;
			// calendar.overlayVisible = false;
			if (calendar.modelValue.length != 0) {
				calendar.overlayVisible = false;
			}
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
		sionomsm(data){
			if(data==1){
				// var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.frmSolicitud.funcionario);
				this.num_cel = this.solicitudes.celular;
				return 'Si'
			}else{
				return 'No'
			}
		},
		sionocorreo(data){
			if(data==1){
				// var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.frmSolicitud.funcionario);
				this.correo = this.solicitudes.correo;
				return 'Si'
			}else{
				return 'No'
			}
		},
		nombretipovehiculo({descripcion,cantidad_pasajeros}){
            return descripcion+'-'+cantidad_pasajeros+' Pasajeros';
        },
		clearFrmSolicitud(){
			this.frmSolicitud.fecha_hora_inicio = '';
			this.frmSolicitud.fecha_hora = '';
			this.frmSolicitud.tiposervicio = '';
			this.frmSolicitud.tipovehiculo = '';
			this.frmSolicitud.centrocostos = '';
			this.frmSolicitud.funcionario = '';
			this.frmSolicitud.origen = '';
			this.frmSolicitud.indexdetalle = '';
			this.frmSolicitud.destino = '';
			this.frmSolicitud.placa = '';
			this.frmSolicitud.notas = '';
			this.frmSolicitud.msm = false;
			this.frmSolicitud.notify_correo = false;
			this.editando = false;
			this.detalle_solicitud = [];
			this.num_cel = '';
			this.correo = '';
			// this.product = {};
		},
		agregarsolicitud(){
			this.frmSolicitud.nombre_user = this.nombreuser;
			this.frmSolicitud.fecha_hora_inicio = this.frmSolicitud.fecha_hora_inicio.toLocaleString();
			this.submitted = false;
			this.frmSolicitud.fecha_hora = new Date().toLocaleString();
			if(!this.frmSolicitud.tiposervicio || !this.frmSolicitud.tipovehiculo || !this.frmSolicitud.centrocostos || !this.frmSolicitud.funcionario || !this.frmSolicitud.fecha_hora_inicio || !this.frmSolicitud.origen || !this.frmSolicitud.destino){
				this.submitted = true;
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor llenar todos los campos', life: 4000});
			}else{
				const cookieName = 'IdCustomer';
				
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieName];
				if(this.correo==true){
					this.frmSolicitud.notify_correo = 1;
				}else{
					this.frmSolicitud.notify_correo = 0;
				}
				if(this.num_cel==true){
					this.frmSolicitud.msm = 1;
				}else{
					this.frmSolicitud.msm = 0;
				}
				var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.frmSolicitud.funcionario);
				var nombre_origen = this.origenes.find((origin) => origin.id == this.frmSolicitud.origen);
				var nombre_destino = this.destinos.find((destin) => destin.id == this.frmSolicitud.destino);
				this.detalle_solicitud.push({
                    id: 0,
                    num_solicitud: this.frmSolicitud.num_solicitud,
                    fecha_hora: moment(new Date()).format('LLLL'),
                    nombre_user: this.nombreuser,
                    tiposervicio: this.frmSolicitud.tiposervicio,
                    tipovehiculo: this.frmSolicitud.tipovehiculo,
                    centrocostos: this.frmSolicitud.centrocostos,
                    funcionario: this.frmSolicitud.funcionario,
					nombre_funcionario: nombre_funcionario.nombre,
                    fecha_hora_inicio: this.frmSolicitud.fecha_hora_inicio,
                    origen: this.frmSolicitud.origen,
					nombre_origen : nombre_origen.nombre,
                    destino: this.frmSolicitud.destino,
					nombre_destino : nombre_destino.nombre,
					msm: this.frmSolicitud.msm,
					placa: this.frmSolicitud.placa,
					notas: this.frmSolicitud.notas,
					notify_correo: this.frmSolicitud.notify_correo,
					id_customer: id_customer,
					id_generador: this.selectgenerador.id
                });
				this.$toast.add({severity:'success', summary: 'Agregado correctamente', detail:'Para guardar los datos click en "Crear Solicitud"', life: 4000});
			}
		},
		editarsolicitud(){
			this.frmSolicitud.nombre_user = this.nombreuser;
			this.frmSolicitud.fecha_hora_inicio = this.frmSolicitud.fecha_hora_inicio.toLocaleString();
			this.submitted = false;
			if(!this.frmSolicitud.tiposervicio || !this.frmSolicitud.tipovehiculo || !this.frmSolicitud.centrocostos || !this.frmSolicitud.funcionario || !this.frmSolicitud.fecha_hora_inicio || !this.frmSolicitud.origen || !this.frmSolicitud.destino){
				this.submitted = true;
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor llenar todos los campos', life: 4000});
			}else{
				var nombre_funcionario = this.funcionarios.find((funci) => funci.id == this.frmSolicitud.funcionario);
				var nombre_origen = this.origenes.find((origin) => origin.id == this.frmSolicitud.origen);
				var nombre_destino = this.destinos.find((destin) => destin.id == this.frmSolicitud.destino);

				var detalle= JSON.parse(JSON.stringify(this.detalle_solicitud[this.indexdetalle]));
					detalle.fecha_hora_inicio = this.frmSolicitud.fecha_hora_inicio,
					detalle.fecha_hora = this.frmSolicitud.fecha_hora,
					detalle.tiposervicio = this.frmSolicitud.tiposervicio,
					detalle.tipovehiculo = this.frmSolicitud.tipovehiculo,
					detalle.centrocostos = this.frmSolicitud.centrocostos,
					detalle.funcionario = this.frmSolicitud.funcionario,
					detalle.nombre_funcionario = nombre_funcionario.nombre,
					detalle.origen = this.frmSolicitud.origen,
					detalle.nombre_origen = nombre_origen.nombre,
					detalle.destino = this.frmSolicitud.destino,
					detalle.nombre_destino = nombre_destino.nombre,
					detalle.msm = this.frmSolicitud.msm,
					detalle.placa = this.frmSolicitud.placa,
					detalle.notas = this.frmSolicitud.notas,
					detalle.notify_correo = this.frmSolicitud.notify_correo,

				this.$toast.add({severity:'success', summary: 'Editado correctamente', detail:'Para guardar los datos click en "Crear Solicitud"', life: 4000});
				this.detalle_solicitud.splice(this.indexdetalle,1,detalle);
				// this.clearFrmSolicitud();
				this.editando= false;
				}
		},
		editDetalleSolicitud(data,slotProps){
			this.indexdetalle = slotProps.index;
			this.editando= true;
			this.frmSolicitud.fecha_hora_inicio = data.fecha_hora_inicio;
			this.frmSolicitud.fecha_hora = data.fecha_hora;
			this.frmSolicitud.tiposervicio = data.tiposervicio;
			this.frmSolicitud.tipovehiculo = data.tipovehiculo;
			this.frmSolicitud.centrocostos = data.centrocostos;
			this.frmSolicitud.funcionario = data.funcionario;
			this.frmSolicitud.origen = data.origen;
			this.frmSolicitud.destino = data.destino;
			this.frmSolicitud.msm = data.msm;
			this.frmSolicitud.placa = data.placa;
			this.frmSolicitud.notas = data.notas;
			this.frmSolicitud.notify_correo = data.notify_correo;
		},
		crearsolicitud(){
			// var _that = this;
			this.loader = true;
			this.submitted = false;
			this.detalle_solicitud.forEach(detalle => {
                detalle.fecha_hora_inicio = moment(detalle.fecha_hora_ini).format('YYYY-MM-DDThh:mm');
				// detalle.id_customer = id_customer;
				// detalle.id_generador = this.selectgenerador.id;

            });
			
			axios.post('/guardarsolicitud', {detalle_solicitud: this.detalle_solicitud}).then(response => {
				console.log(response.data.data);
				this.loader = false;
				this.productDialog = false;
				this.modalselectempresa = false;
				const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];
				this.loadDataTable(id_customer);
			}).catch(function (error) {
				console.log(error);
				this.loader = false;

			});
		},
		
		editarsolicitudcompleta(){
			// var _that = this;
			this.loader = true;
			if(this.detalle_solicitud.length==0){
				this.loader = false;
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor ingresar todos los campos', life: 4000});
			}else{
				this.loader = false;
			}
		},
		deleteCostos(){
			var _that = this;
			var buttoneliminar = document.getElementById('buttoneliminar');
			buttoneliminar.setAttribute("disabled", "");
			
			this.loader = true;
			axios.delete('eliminar_solicitud/' + this.frmSolicitud.id)
			.then(response => {
				// this.result.splice(id, 1)
				_that.loadDataTable(_that.frmSolicitud.id_customer);
				console.log(response);
				// _that.loader = false;
				_that.clearFrmSolicitud();
				_that.deleteProductDialog=false;
				_that.$toast.add({severity:'success', summary: 'Eliminación exitosa', life: 4000});
			}).catch(error => {
				console.log(error);
				buttoneliminar.removeAttribute('disabled');

				_that.loader = false;
				_that.loadDataTable(_that.frmSolicitud.id_customer);
				_that.clearFrmSolicitud();
			});
		},
		deleteCostosTodos(){
			var _that = this;
			var buttoneliminar = document.getElementById('buttoneliminar');
			buttoneliminar.setAttribute("disabled", "");
			
			this.loader = true;
			axios.post('/eliminar_todos_solicitud', {id: this.frmSolicitud.id}).then(response => {
				// _that.generadores = response.data.data;
				console.log(response.data.message);
				_that.loadDataTable(_that.frmSolicitud.id_customer);
			}).catch(function (error) {
				console.log(error);
			});
		},
		loadData(id_user_rol){
			this.generadores = [];
			axios.post('/cargar_generadores', {usuario_rol: id_user_rol}).then(response => {
				this.generadores = response.data.data;
				// console.log(response.data.data);

			}).catch(function (error) {
				console.log(error);
			});
		},
		export_historicos(){
			var buttonexports = document.getElementById('buttonexports');
			buttonexports.setAttribute("disabled", "");
			const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
			let id_customer = cookie[cookieIdCustomer];
			var url = '/export_historicos';
			var _that = this;
			this.loader = true;
			axios({
				url: url,
				method: 'POST',
				data: {id_customer: id_customer,frmFilterExports: this.frmFilterExports},
				responseType: 'blob',
			}).then((response) => {
				buttonexports.removeAttribute('disabled');
			_that.forceFileDownload(response);
			_that.loader = false;
			}).catch(function (error) {
				buttonexports.removeAttribute('disabled');
				_that.loader = false;
				if(error.response.status){
					_that.$toast.add({severity:'error', summary: 'Error', detail:'No existe un funcionario con esta cédula', life: 5000});
				}
				
			});
		},
		forceFileDownload(response){
			var nombrearchivo = 'reporte.xlsx';
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', nombrearchivo); //or any other extension
			document.body.appendChild(link);
			link.click();
		},
		loadDataTable(id_customer){
			this.solicitudes = [];
			this.num_solicitud = '';
			this.loader = true;
			var _that = this;
			axios.post('/cargar_solicitudes', {id_customer: id_customer}).then(response => {
				this.solicitudes = response.data.data;
				
				_that.loader = false;
			}).catch(function (error) {
				console.log(error);
				_that.loader = false;
			});
		},
		loadDataTableHistoricos(id_customer){
			this.solicitudes_historicos = [];
			this.num_solicitud = '';
			this.loader = true;
			var _that = this;
			axios.post('/cargar_solicitudes_historicos', {id_customer: id_customer}).then(response => {
				_that.loader = false;
				_that.solicitudes_historicos = response.data.data.data;
				_that.pagination = response.data.data;
				
			}).catch(function (error) {
				console.log(error);
				_that.loader = false;
			});
		},
		clearFrmCostos(){
			this.frmCostos.codigo = '';
			this.frmCostos.id_costos = '';
			this.frmCostos.descripcion = '';
			this.frmCostos.estado = '';
			this.frmCostos.id_customer = '';
			this.frmCostos.id_generador = '';
		},
		closeHistoricos(){
			this.modalFiltros = true;
			this.frmFilterExports.fecha_ini = '';
			this.frmFilterExports.fecha_fin = '';
			this.frmFilterExports.num_solicitud = '';
			this.frmFilterExports.estado = '';
			this.frmFilterExports.cedula_funcionario = '';
			this.frmFilterExports.rangofechasolicitud = false;
		},
		openNew(){
			this.selectgenerador = [];
			if(this.generadores.length==1){
				this.selectgenerador = this.generadores[0];
				this.product = {};
				this.submitted = false;
				
				const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];
				this.clearFrmCostos();
				this.clearFrmSolicitud();
				
				// this.frmSolicitud.num_solicitud = this.num_solicitud;
				this.nombregenerador = this.selectgenerador.generador.razon_social;
				this.cargar_selects(id_customer,this.selectgenerador.id_generador);
				
			}else{
				this.modalselectempresa = true;
			}
			
		},
		openNewblank() {
			if(this.selectgenerador.length==0){
				this.$toast.add({severity:'error', summary: 'Favor seleccionar una empresa', detail:'', life: 4000});
			}else{
				const buttonlisto = document.getElementById('buttonlisto');
				buttonlisto.setAttribute("disabled", "");
				this.product = {};
				this.submitted = false;
				
				const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];
				this.clearFrmCostos();
				this.clearFrmSolicitud();
				
				// this.frmSolicitud.num_solicitud = Math.floor(Math.random() * 99999);
				
				this.nombregenerador = this.selectgenerador.generador.razon_social;
				this.cargar_selects(id_customer,this.selectgenerador.id_generador);
				buttonlisto.removeAttribute('disabled');
			}
		},
		cargar_selects(id_customer,id_generador){
			
			this.loader = true;
			this.tipo_servicios = [];
			axios.post('/cargar_selects', {id_customer: id_customer,id_generador: id_generador}).then(response => {
				this.tipo_servicios = response.data.tiposervicio;
				this.centro_costos = response.data.centrocostos;
				this.clase_vehiculo = response.data.tipovehiculo;
				this.funcionarios = response.data.funcionarios;
				this.origenes = response.data.origen;
				this.destinos = response.data.destino;
				this.num_solicitud = '0000'+(response.data.cuantas_solicitudes+1);
				this.frmSolicitud.num_solicitud = this.num_solicitud;
				this.loader = false;
				this.productDialog = true;
			}).catch(function (error) {
				console.log(error);
			});
		},
		cargar_clase_vehiculo(){
			this.clase_vehiculo = [];
			axios.get('/cargar_clase_vehiculo').then(response => {
				this.clase_vehiculo = response.data.data;

			}).catch(function (error) {
				console.log(error);
			});
		},
		cargar_centro_costos(id_customer){
			
			this.centro_costos = [];
			this.loader = true;
			var _that = this;
			axios.post('/cargar_centro_costos_activos', {id_customer: id_customer}).then(response => {
				this.centro_costos = response.data.data;
				_that.loader = false;
			}).catch(function (error) {
				console.log(error);
				_that.loader = false;
			});
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		anularSolicitud(product) {
			this.clearFrmSolicitud();
			this.frmSolicitud.tiposervicio = product.id_tipo_servicio;
			this.frmSolicitud.tipovehiculo = product.id_clase_vehiculo;
			this.frmSolicitud.centrocostos = product.id_centro_costo;
			this.frmSolicitud.funcionario = product.id_funcionario;
			this.frmSolicitud.fecha_hora_inicio = product.fecha_hora_inicio;
			this.frmSolicitud.origen = product.id_origen;
			this.frmSolicitud.destino = product.id_destino;
			this.frmSolicitud.placa = product.placa;
			this.frmSolicitud.notas = product.notas;
			this.frmSolicitud.num_solicitud = product.num_solicitud;
			this.frmSolicitud.id_generador = product.id_generador;
			this.frmSolicitud.id_customer = product.id_customer;
			this.frmSolicitud.id = product.id;
			this.deleteProductAnular = true;
		},
		anularSolicitudUno(){
			this.loader = true;
			var _that = this;
			axios.post('/anular_solicitud', {id: this.frmSolicitud.id}).then(response => {
				// this.centro_costos = response.data.data;
				_that.deleteProductAnular = false;
				_that.loadDataTable(_that.frmSolicitud.id_customer);
				// _that.loader = false;
			}).catch(function (error) {
				
				// console.log(error.response.data.message);
				_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 5000});
				_that.loader = false;
			});
		},
		anularSolicitudTodos(){
			this.loader = true;
			var _that = this;
			axios.post('/anular_todos_solicitud', {id: this.frmSolicitud.id}).then(response => {
				// this.centro_costos = response.data.data;
				_that.loadDataTable(_that.frmSolicitud.id_customer);
				_that.deleteProductAnular = false;
				// console.log(response);
				// _that.loader = false;
			}).catch(function (error) {
				// console.log(error.response.data.message);
				_that.$toast.add({severity:'error', summary: 'Error', detail:error.response.data.message, life: 5000});
				_that.loader = false;
			});
		},
		editProduct(product) {
			this.clearFrmSolicitud();
			const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];

			var gene = this.generadores.find((usuario) => usuario.id == product.id_generador);
			this.cargar_selects(id_customer,gene.id_generador);
			this.frmSolicitud.tiposervicio = product.id_tipo_servicio;
			this.frmSolicitud.tipovehiculo = product.id_clase_vehiculo;
			this.frmSolicitud.centrocostos = product.id_centro_costo;
			this.frmSolicitud.funcionario = product.id_funcionario;
			this.frmSolicitud.fecha_hora_inicio = product.fecha_hora_inicio;
			this.frmSolicitud.origen = product.id_origen;
			this.frmSolicitud.destino = product.id_destino;
			this.frmSolicitud.placa = product.placa;
			this.frmSolicitud.notas = product.notas;
			this.frmSolicitud.num_solicitud = product.num_solicitud;
			this.nombregenerador = gene.generador.razon_social;
            // this.selectgenerador = gene;
			this.product = {...product};
			// this.productDialog = true;
		},
		editaruno(){
			this.clearFrmSolicitud();
			// this.productDialog = true;
			const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];
			this.editando = false;
			var gene;
			if(this.generadores.length>1){
				gene = this.generadores.find((usuario) => usuario.id == this.product.id_generador);
			}else{
				gene = this.generadores[0];
			}
			var event_sms;
			var event_correo;
			this.cargar_selects(id_customer,gene.id_generador);
			if(this.product.eventos_sms==1){
				event_sms = true;
			}else{
				event_sms = false;
			}
			if(this.product.notify_correo==1){
				event_correo = true;
			}else{
				event_correo = false;
			}
			this.frmSolicitud.num_solicitud = this.product.num_solicitud;
			this.detalle_solicitud.push({
                    id: this.product.id,
                    num_solicitud: this.product.num_solicitud,
                    fecha_hora: this.product.fecha_hora,
                    nombre_user: this.nombreuser,
                    tiposervicio: this.product.id_tipo_servicio,
                    tipovehiculo: this.product.id_clase_vehiculo,
                    centrocostos: this.product.id_centro_costo,
                    funcionario: this.product.id_funcionario,
					nombre_funcionario: this.product.funcionario.nombre+' '+this.product.funcionario.apellido,
					// nombre_funcionario: this.product.funcionario.nombre,
                    fecha_hora_inicio: this.product.fecha_hora_inicio,
                    origen: this.product.id_origen,
					nombre_origen: this.product.origen.nombre,
                    destino: this.product.id_destino,
					nombre_destino: this.product.destino.nombre,
					msm: event_sms,
					placa: this.product.placa,
					notas: this.product.notas,
					notify_correo: event_correo,
					id_customer: id_customer,
					id_generador: this.product.id_generador
                });
		},
		editartodos(){
			this.clearFrmSolicitud();
			// this.productDialog = true;
			this.editando = false;
			this.loader = true;
			axios.post('/cargar_todos_solicitud', {id_customer: this.product.id_customer,id_generador: this.product.id_generador,num_solicitud: this.product.num_solicitud}).then(response => {
				// console.log(response.data.data);
				this.tipo_servicios = response.data.tiposervicio;
				this.centro_costos = response.data.centrocostos;
				this.clase_vehiculo = response.data.tipovehiculo;
				this.funcionarios = response.data.funcionarios;
				this.origenes = response.data.origen;
				this.destinos = response.data.destino;
				response.data.data.forEach(detalle => {
					var event_sms;
					var event_correo;
					if(detalle.eventos_sms==1){
						event_sms = true;
					}else{
						event_sms = false;
					}
					if(detalle.eventos_correo==1){
						event_correo = true;
					}else{
						event_correo = false;
					}
					
					this.frmSolicitud.num_solicitud = detalle.num_solicitud;
					// var nombre_funcionario = response.data.funcionarios.find((funci) => funci.id == detalle.id_funcionario);
					this.detalle_solicitud.push({
						id: detalle.id,
						num_solicitud: detalle.num_solicitud,
						fecha_hora: detalle.fecha_hora,
						nombre_user: detalle.nombre_user,
						tiposervicio: detalle.id_tipo_servicio,
						tipovehiculo: detalle.id_clase_vehiculo,
						centrocostos: detalle.id_centro_costo,
						funcionario: detalle.id_funcionario,
						nombre_funcionario: detalle.funcionario.nombre+' '+detalle.funcionario.apellido,
						fecha_hora_inicio: detalle.fecha_hora_inicio,
						origen: detalle.id_origen,
						nombre_origen: detalle.origen.nombre,
						destino: detalle.id_destino,
						nombre_destino: detalle.destino.nombre,
						msm: event_sms,
						placa: detalle.placa,
						notas: detalle.notas,
						notify_correo: event_correo,
						id_customer: detalle.id_customer,
						id_generador: detalle.id_generador
					});
				});
				
				this.loader = false;
				this.productDialog = true;
			}).catch(function (error) {
				console.log(error);
			});
		},
		editProductPregunta(product) {
			this.clearFrmSolicitud();
			const cookieIdCustomer = 'IdCustomer';
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				});
				let id_customer = cookie[cookieIdCustomer];
			this.abrireditar = true;
			var gene;
			if(this.generadores.length>1){
				gene = this.generadores.find((usuario) => usuario.id == product.id_generador);
				// console.log(gene);
			}else{
				gene = this.generadores[0];
			}
			// console.log(id_customer);
			// this.cargar_selects(id_customer,gene.id_generador);
			this.frmSolicitud.tiposervicio = product.id_tipo_servicio;
			this.frmSolicitud.tipovehiculo = product.id_clase_vehiculo;
			this.frmSolicitud.centrocostos = product.id_centro_costo;
			this.frmSolicitud.funcionario = product.id_funcionario;
			this.frmSolicitud.fecha_hora_inicio = product.fecha_hora_inicio;
			this.frmSolicitud.origen = product.id_origen;
			this.frmSolicitud.destino = product.id_destino;
			this.frmSolicitud.placa = product.placa;
			this.frmSolicitud.notas = product.notas;
			this.frmSolicitud.num_solicitud = product.num_solicitud;
			this.nombregenerador = gene.generador.razon_social;
            // this.selectgenerador = gene;
			this.product = {...product};
			// this.productDialog = true;
		},
		confirmDetalleSolicitud(data,slotProps) {
			// this.product = product;
			// console.log(data);
			this.detalle_solicitud.splice(slotProps.index,1);
			this.$toast.add({severity:'success', summary: 'Eliminado correctamente', detail:'', life: 4000});

			// this.frmCostos.codigo = product.codigo;
			// this.frmCostos.id_costos = product.id;
			// this.frmCostos.descripcion = product.descripcion;
			// this.frmCostos.id_customer = product.id_customer;
			// this.frmCostos.id_generador = product.id_generador;
			// this.frmCostos.estado = product.estado.toString();
			// this.deleteProductDialog = true;
		},
		confirmDeleteProduct(product) {
			// this.product = product;
			// console.log(product);
			this.frmSolicitud.tiposervicio = product.id_tipo_servicio;
			this.frmSolicitud.tipovehiculo = product.id_clase_vehiculo;
			this.frmSolicitud.centrocostos = product.id_centro_costo;
			this.frmSolicitud.funcionario = product.id_funcionario;
			this.frmSolicitud.fecha_hora_inicio = product.fecha_hora_inicio;
			this.frmSolicitud.origen = product.id_origen;
			this.frmSolicitud.destino = product.id_destino;
			this.frmSolicitud.placa = product.placa;
			this.frmSolicitud.notas = product.notas;
			this.frmSolicitud.num_solicitud = product.num_solicitud;
			this.frmSolicitud.id_generador = product.id_generador;
			this.frmSolicitud.id_customer = product.id_customer;
			this.frmSolicitud.id = product.id;
			this.deleteProductDialog = true;
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
// @import '../assets/demo/styles/badges.scss';

</style>
